import React from "react";
import './index.css';
import { IdSectionServicesCosts } from "../constants";

export default function SectionServicesCosts() {
  return (
    <div id={IdSectionServicesCosts} className={IdSectionServicesCosts}>
      <h3>Стоимость услуг:</h3>
      <div className="disclaimer-box">
        Размер гонорара устанавливается индивидуально и всегда зависит от сложности дела.
      </div>
      <div className="services-costs-container">
        <div className="civil-cases">
          <h4>Гражданские дела:</h4>
          <table>
            <tr>
              <td>Устная консультация</td>
              <td>от 2000 руб.*</td>
            </tr>
            <tr>
              <td>Письменная консультация</td>
              <td>от 2000 руб./час</td>
            </tr>
            <tr>
              <td>Написание искового заявления</td>
              <td>от 2000 руб.</td>
            </tr>
            <tr>
              <td>Ведение дела</td>
              <td>от 10 000 руб.</td>
            </tr>
            <tr>
              <td>Сопровождение сделок с недвижимостью</td>
              <td>от 10 000 руб.</td>
            </tr>
            <tr>
              <td>Раздел совместно нажитого имущества (бывших) супругов</td>
              <td>от 10 000 руб.</td>
            </tr>
            <tr>
              <td>Расторжение брака без участия сторон</td>
              <td>25 000 руб.</td>
            </tr>
            <tr>
              <td>Представление интересов в спорах о наследовании недвижимого имущества (дачи, квартиры, дома, земельные участки).</td>
              <td>от 15 000 руб.</td>
            </tr>
            <tr>
              <td>Представление интересов в получение права собственности при наследовании на дачи в СНТ, гаражи в гаражных кооперативах. </td>
              <td>от 25 000 руб.</td>
            </tr>
            <tr>
              <td>Представление интересов в делах по искам о возмещении вреда, причинённого преступлением, виновником в ДТП </td>
              <td>от 28 000 руб.</td>
            </tr>
            <tr>
              <td>Участие в судебном заседании</td>
              <td>от 15 000 - 20 000 руб.</td>
            </tr>
          </table>
          <p className="asterisk-comments">* для пенсионеров и инвалидов 1000 руб./час</p>
        </div>
        <hr />
        <div className="arbitration-cases">
          <h4>Арбитражные дела:</h4>
          <table>
            <tr>
              <td>Консультация</td>
              <td>5000 руб./час</td>
            </tr>
            <tr>
              <td>Участие в делах субсидиарной ответственности</td>
              <td>от 10 000 руб.</td>
            </tr>
            <tr>
              <td>Изготовление иска в суд</td>
              <td>от 5000 руб.</td>
            </tr>
            <tr>
              <td>Участие в делах о банкротстве физических лиц</td>
              <td>от 15 000 руб.</td>
            </tr>
            <tr>
              <td>Защита интересов физического лица при банкротстве о признании сделок банкрота недействительными</td>
              <td>от 25 000 руб.</td>
            </tr>
            <tr>
              <td>Споры о наследовании имущества (долей) предприятий, обществ</td>
              <td>от 18 000 руб.</td>
            </tr>
            <tr>
              <td>Споры о разделе имущества (долей) предприятий, обществ при разводе (расторжении брака)</td>
              <td>от 15 000 руб.</td>
            </tr>
            <tr>
              <td>Участие в судебном заседании</td>
              <td>от 15 000 - 20 000 руб.</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}

