import React from 'react';
import './index.css';

export default function Footer() {
  return (
    <div className="footer">
      <p>© 2009-{new Date().getFullYear()}</p>
    </div>
  );
}
