import React from "react";
import './index.css';

interface Props {
  main: string;
  description: string;
}

const Fact = (props: Props) => {
  const { main, description } = props;

  return (
    <div className="fact">
      <div className="fact-main">
        {main}
      </div>
      <div className="fact-description">
        {description}
      </div>
    </div>
  )
}

export default function Facts() {
  return (
    <div className="facts">
      <Fact main="c 1997 г." description="юридическая практика" />
      <Fact main="более 2000" description="судебных дел" />
    </div>
  );
}