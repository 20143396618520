import React from 'react';
import Footer from '../footer';
import LandingScreen from '../landing_screen';
import Navbar from '../navbar';
import SectionAbout from '../section_about';
import SectionContacts from '../section_contacts';
import SectionContactsShort from '../section_contacts_short';
import SectionServices from '../section_services';
import SectionServicesCosts from '../section_services_costs';
import './index.css';

export default class App extends React.Component {
  render() {
    return (
      <div className="app">
        <Navbar />
        <LandingScreen />
        <div className='content-container'>
          <SectionContactsShort useShadow={true} />
          <SectionAbout />
          <SectionServices />
          <SectionServicesCosts />
          <SectionContacts />
          <Footer />
        </div>
      </div>
    );
  }
}