import { scroller } from 'react-scroll';
import { IdSectionContactsShort } from '../constants';
import { ScrollParams } from '../navbar';
import { Phone } from '../section_contacts_short';
import { ReactComponent as Logo } from './favicon.svg';
import './index.css';

const onArrowClick = () => {
  const params = { ...ScrollParams};
  scroller.scrollTo(IdSectionContactsShort, params);
}

export default function LandingScreen() {
  return (
    <div className='landing-screen-container'>
      <div className='landing-screen-hatch'>
        <div className='landing-screen-content-container'>
          <Logo />
          <div className='landing-screen-content-name-additional'>
            <h2 >Иркутский юридический центр</h2>
          </div>
          <div className='landing-screen-content-name-main'>
            <h1>Евгения Козлова</h1>
          </div>
          <div className='landing-screen-content-slogan'>
            <h2>Надежный партнер в юридических вопросах</h2>
          </div>
          <div className='landing-screen-content-phone' >
            <Phone />
          </div>
          <div
            className='arrow-container'
            onClick={onArrowClick}
          >
            <i
              className="arrow-down bounce"
              onClick={onArrowClick}
            />
          </div>
          {/* <div className='landing-screen-content-footer'>
          </div> */}
        </div>
      </div>
    </div>
  )
}