import React from "react";
import './index.css';
import { IdSectionServices } from "../constants";

interface Props {
  header: string;
  description: string;
  list: string[];
}

const Service = (props:Props) => {
  const { header, description, list } = props;
  const liBullets = list.map(value => (<li>{value}</li>));
  return (
    <div className="service">
      <h4>{header}</h4>
      <p>{description}</p>
      <ul>
        {liBullets}
      </ul>
    </div>
  )
}

export default function SectionServices() {
  return (
    <div id={IdSectionServices} className={IdSectionServices}>
      <h3>Услуги:</h3>
      <div className="services-container">
        <Service
          header="Гражданские дела"
          description=""
          list={[
            "жилищные споры",
            "семейные споры",
            "наследство и наследственные споры",
            "трудовые споры",
            "дорожно-транспортные происшествия (ДТП)",
            "защита прав потребителей",
            "споры со страховыми компаниями по кредитам",
            "споры со страховыми компаниями по страхованию жизни",
          ]}
        />
        <hr />
        <Service
          header="Арбитражные дела"
          description="Дела, связанные с осуществлением предпринимательской и иной экономической деятельности:"
          list={[
            "субсидиарная ответственность физических лиц",
            "налоговые споры",
            "процедура банкротства",
            "взыскание долгов",
            "дела между хозяйствующими субъектами",
          ]}
        />
      </div>
    </div>
  );
}