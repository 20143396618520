import React, { Component } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import './index.css';
import { IdSectionContactsShort, IdSectionAbout, IdSectionServices, IdSectionServicesCosts, IdSectionContacts } from "../constants";

export const ScrollParams = {
  offset: -140,
  duration: 200,
  spy: true,
  smooth: true,
};

export default class Navbar extends Component {
  scrollToTop = () => {
    scroll.scrollToTop({duration: ScrollParams.duration});
  };

  render() {
    return (
      <nav className="nav" id="navbar">
        <div className="nav-content">
          <img
            src="/logo.svg"
            className="nav-logo"
            alt="Адвокат Евгений Козлов"
            onClick={this.scrollToTop}
          />
          <ul className="nav-items">
            <li className="nav-item">
              <Link
                activeClass="active"
                to={IdSectionContactsShort}
                spy={ScrollParams.spy}
                smooth={ScrollParams.smooth}
                offset={ScrollParams.offset}
                duration={ScrollParams.duration}
              >
                Связаться
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to={IdSectionAbout}
                spy={ScrollParams.spy}
                smooth={ScrollParams.smooth}
                offset={ScrollParams.offset}
                duration={ScrollParams.duration}
              >
                Об адвокате
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to={IdSectionServices}
                spy={ScrollParams.spy}
                smooth={ScrollParams.smooth}
                offset={ScrollParams.offset}
                duration={ScrollParams.duration}
              >
                Услуги
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to={IdSectionServicesCosts}
                spy={ScrollParams.spy}
                smooth={ScrollParams.smooth}
                offset={ScrollParams.offset}
                duration={ScrollParams.duration}
              >
                Стоимость услуг
              </Link>
            </li>
            <li className="nav-item">
              <Link
                activeClass="active"
                to={IdSectionContacts}
                spy={ScrollParams.spy}
                smooth={ScrollParams.smooth}
                offset={ScrollParams.offset}
                duration={ScrollParams.duration}
              >
                Контакты
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}
