import React from "react";
import { IdSectionContactsShort } from "../constants";
import './index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';


export function Phone() {
  return (
    <a
      href="tel:+79148967185"
      onClick={() => {
        // @ts-ignore: 
        window.ym(5092726, 'reachGoal', 'clickPhone7185')
      }}
      target="_blank"
      rel="noreferrer"
    >
      +7 (914) 896-71-85
    </a>
  );
}

interface Props {
  useShadow: boolean;
}

export default function SectionContactsShort(props: Props) {
  const { useShadow } = props;
  const componentClassName = useShadow ? "contacts-short shadow" : "contacts-short";
  return (
    <div
      id={IdSectionContactsShort}
      className={componentClassName}
    >
      <div className="contacts-short-text">
        Связаться (г. Иркутск):
      </div>
      <div className="contacts-short-phone">
        <ul>
          <li>
            <FontAwesomeIcon icon={faPhone} />
            <Phone />
          </li>
          <li>
            <FontAwesomeIcon icon={faTelegram} />
            <a
              href="https://t.me/+79148967185"
              onClick={() => {
                // @ts-ignore: 
                window.ym(5092726, 'reachGoal', 'clickTelegram7185')
              }}
              target="_blank"
              rel="noreferrer"
            >
              Telegram
            </a>
          </li>
        </ul>
      </div>
      <div className="contacts-short-write">
        <ul>
          <li>
            <FontAwesomeIcon icon={faWhatsapp} />
            <a
              href="https://wa.me/79148967185"
              onClick={() => {
                // @ts-ignore: 
                window.ym(5092726, 'reachGoal', 'clickWhatsUp')
              }}
              target="_blank"
              rel="noreferrer"
            >
              WhatsApp
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faEnvelope} />
            <a
              href="mailto:advokat@evkozlov.ru"
              onClick={() => {
                // @ts-ignore: 
                window.ym(5092726, 'reachGoal', 'clickEmail')
              }}
              target="_blank"
              rel="noreferrer"
            >
              advokat@evkozlov.ru
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
