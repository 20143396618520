import React from "react";
import { IdSectionContacts } from "../constants";
import SectionContactsShort from '../section_contacts_short';
import './index.css';

export default function SectionContacts() {
  return (
    <div id={IdSectionContacts} className={IdSectionContacts}>
      <h3>Контакты:</h3>
      <div className="contacts-container">
        <div className="contacts-container-column1">
          <div className="name">
            Адвокат Козлов Евгений Викторович
          </div>
          <SectionContactsShort useShadow={false} />
        </div>
        <div className="contacts-container-column2">
          <div className="address-text">
            <b>Адрес</b>: г. Иркутск, ул. Лермонтова д.142 2-й этаж Кабинет 28, остановка "Институт микрохирургия глаза"
            <br />
            (кликнуть картинку чтобы открыть карты)
          </div>
          <div className="address-map">
            <a
              href="https://yandex.ru/maps/-/CCUFzVxePA"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                // @ts-ignore: 
                return window.ym(5092726, 'reachGoal', 'clickMap');
              }}
            >
              <img src="/map.png" alt="кликнуть чтобы открыть карты" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
