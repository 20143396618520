import React from "react";
import { IdSectionAbout } from "../constants";
import Facts from "../facts";
import './index.css';

export default function SectionAbout() {
  return (
    <div id={IdSectionAbout} className={IdSectionAbout}>
      <div className="info-container">
        <div className="info-1">
          <h2>Юридический центр помощи в судах по гражданским делам, г. Иркутск</h2>
          <p>
            Юридический стаж более 20 лет позволяет мне решать правовые задачи клиентов любой сложности.
          </p>
          <Facts />
        </div>
        <div className="info-2">
          <h3>Опыт и квалификация:</h3>
          <p>
            Статус адвоката. Ведение дел в Арбитражных, Гражданских, Административных процессах любой сложности, на любом этапе процесса в судах любого уровня, включая Военные суды.
            Юридическая практика с 1997г. в должности юрисконсульта на предприятии. Частная практика с 2009г.
          </p>
          <p>
            Призер конкурса "Лучший адвокат Сибири и Дальнего Востока" за серию публикаций в СМИ по разъяснению законодательства.
          </p>
          <p>
            Работал в Судебном департаменте при Верховном суде в Иркутской области. Преподавал на юридическом факультете Восточносибирского технологического университета.
          </p>
        </div>
      </div>
    </div >
  );
}
